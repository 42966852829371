<template>
    <base-card class="card mandate-detail">
        <div class="card-title">
            <h2 class="fsb-typo-heading-2">{{ t('mandate') }}</h2>
        </div>
        <div class="mandate-details">
            <div class="detail-item" v-for="media in mandateObj.mandate_medias" :key="media">
                <span class="capitalize-first fsb-typo-para-regular detail-label">{{ media }}</span>
                <div class="action-container">
                    <mandate-tag :mandate-status="mandateObj.mandate_status" :validity="mandateObj.validity" @click="openMandateModal" />
                    <icon-button icon-type="renew" @click="openMandateModal" />
                </div>
            </div>
        </div>
        <mandate-form-modal
            :mandateInfos="formatMandate"
            :modal-show="showMandateModal"
            :close-modal="() => closeMandateFormModal()"
            @on-mandate-form-modal-valid="sendMandateInfos"
            :default-values="mandate"
        />
    </base-card>
</template>

<script setup lang="ts">
    import { defineProps, ref, Ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useApiStore } from '@/store/store-index';
    const props = defineProps({
        mandate: Object,
    });
    const apiStore = useApiStore();
    const { t } = useI18n({ useScope: 'global' });
    let showMandateModal: Ref<boolean> = ref(false);
    const mandateObj = computed(() => {
        return props?.mandate?.mandate;
    });
    const formatMandate = {
        mandate_status: props?.mandate?.status ? props?.mandate?.status : null,
        brand: props?.mandate?.brand,
        point_code: props?.mandate?.point_code,
        details: {
            adsNotifyBookId: mandateObj?.value.adsNotifyBookId,
            advertiser: mandateObj?.value.advertiser,
            my_manda_informations: {
                ...mandateObj?.value.my_manda_informations,
            },
            my_manda_medias: {
                ...mandateObj?.value.my_manda_medias,
            },
        },
    };

    if (props?.mandate?.validity != null) {
        formatMandate.validity = props.mandate.validity;
    }
    const openMandateModal = () => {
        showMandateModal.value = true;
    };

    const closeMandateFormModal = () => {
        showMandateModal.value = false;
    };

    const sendMandateInfos = async (payload: any) => {
        try {
            console.log('Sending mandate information:', payload);
            payload = {
                brand: props?.mandate?.brand,
                point_code: props?.mandate?.point_code,
                validity: '2024',
                details: { ...payload.payload },
            };
            const response: any = await apiStore.post('/user/mandate', payload);
            closeMandateFormModal();
        } catch (e) {
            console.error('Error sending mandate:', e);
        }
    };
</script>

<style lang="scss" scoped>
    .card-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .capitalize-first {
        text-transform: lowercase;
    }
    .capitalize-first::first-letter {
        text-transform: uppercase;
    }
    .mandate-details {
        width: 100%;
    }

    .detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .detail-label {
        text-align: left;
        flex: 1;
    }

    .action-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-bottom: 5px;
    }

    .mandate-tag {
        flex-shrink: 0;
    }

    .icon-button {
        background-color: #f5f5f5;
        border-radius: 50%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }

    :deep(.fsb-icon--primary){
        svg{
            path {
                fill: none !important;
            }
        }
    }

</style>
