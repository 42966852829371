<template>
    <div>
        <div>
            <div>
                <h1 class="fsb-typo-heading-1">{{ t('profile') }}</h1>
                <p class="fsb-typo-para-regular">{{ t('updateProfile') }}</p>
                <div id="profile">
                    <div class="input-row">
                        <simple-input
                            id="firstname"
                            :label="t('firstname')"
                            :placeholder="t('firstname')"
                            :default-value="form.firstname"
                            @update:value="(value: any) => updateFormField('firstname', value)"
                        />
                        <simple-input
                            id="lastname"
                            :label="t('surname')"
                            :placeholder="t('surname')"
                            :default-value="form.lastname"
                            @update:value="(value: any) => updateFormField('lastname', value)"
                        />
                    </div>
                    <simple-input
                        id="input-email"
                        :label="t('email')"
                        :placeholder="t('email')"
                        :default-value="form.email"
                        :invalid="invalidEmail"
                        :invalid-label="t('emailRequired')"
                        type="email"
                        @update:value="(value: any) => updateFormField('email', value)"
                        required
                    />
                    <simple-input
                        id="input-telephone"
                        :label="t('phone')"
                        type="tel"
                        :placeholder="t('exPhoneFormat')"
                        :default-value="form.phone"
                        :invalid="invalidPhone"
                        :invalid-label="t('phoneFormat')"
                        @update:value="(value: any) => updateFormField('phone', value)"
                    />
                    <div class="d-flex justify-content-end">
                        <ui-button class="button-reset" v-if="isFormChanged" :label="t('cancel')" variant="white" @click="resetForm" />
                        <ui-button class="button-validate" :disabled="!isFormValid" :label="t('confirm')" variant="primary" @click="handleUpdateProfile" />
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { onMounted, ref, reactive, computed, watch, inject } from 'vue';
    import { useApiStore } from '@/store/store-index';
    import { useI18n } from 'vue-i18n';

    const snack = inject('snackBar');
    const apiStore = useApiStore();
    const { t } = useI18n();
    const invalidEmail = ref(false);
    const invalidPhone = ref(false);

    const form = reactive({
        firstname: apiStore.users.firstname || '',
        lastname: apiStore.users.lastname || '',
        email: apiStore.users.email || '',
        phone: apiStore.users.phone || '',
    });

    const isFormChanged = computed(() => {
        return form.firstname !== apiStore.users.firstname || form.lastname !== apiStore.users.lastname || form.email !== apiStore.users.email || form.phone !== apiStore.users.phone;
    });

    const isFormValid = computed(() => {
        return (
            (form.firstname !== apiStore.users.firstname || form.lastname !== apiStore.users.lastname || form.email !== apiStore.users.email || form.phone !== apiStore.users.phone) &&
            !invalidEmail.value &&
            !invalidPhone.value
        );
    });

    const resetForm = () => {
        Object.assign(form, {
            firstname: apiStore.users.firstname,
            lastname: apiStore.users.lastname,
            email: apiStore.users.email,
            phone: apiStore.users.phone,
        });
        invalidEmail.value = false;
        invalidPhone.value = false;
    };

    const formatPhoneNumber = (value) => {
        let cleaned = value.replace(/[^\d]/g, '');
        if (cleaned.startsWith('0') && cleaned.length === 10) {
            return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
        }

        return value;
    };

    const updateFormField = (fieldName: string, input: any) => {
        let trimmedValue = input.value.trim();
        if (fieldName === 'email') {
            if (trimmedValue.length === 0) {
                invalidEmail.value = true;
            } else {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                invalidEmail.value = !emailRegex.test(trimmedValue);
            }
        } else if (fieldName === 'phone') {
            const formattedPhone = formatPhoneNumber(trimmedValue);
            const phoneRegex = /^0\d{1} \d{2} \d{2} \d{2} \d{2}$/;
            invalidPhone.value = !phoneRegex.test(formattedPhone);
            trimmedValue = formattedPhone;
        }
        form[fieldName] = trimmedValue;
    };

    const handleUpdateProfile = async () => {
        const userPayload = {
            firstname: form.firstname,
            lastname: form.lastname,
            email: form.email,
            phone: form.phone,
        };

        const response = await apiStore.updateProfile(userPayload);
        snack.open(response.success ? 'success' : 'alert', response.success ? t('updatedProfile') : t('errorUpdateProfile'));
    };

    onMounted(async () => {
        await apiStore.init();
    });

    watch(
        () => apiStore.users,
        (newUser) => {
            Object.assign(form, {
                firstname: newUser.firstname,
                lastname: newUser.lastname,
                email: newUser.email,
                phone: newUser.phone,
            });
        },
        { immediate: true }
    );
</script>

<style scoped>
    .fsb-simple-input {
        margin-bottom: 12px;
    }
    .fsb-typo-heading-1 {
        margin-bottom: 6px;
    }
    .fsb-typo-para-regular {
        margin-bottom: 25px;
        color: #707070;
    }
    #profile {
        max-width: 400px;
    }

    .input-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    .button-reset,
    .button-validate {
        margin-top: 20px;
    }
    .button-reset {
        margin-right: 10px;
    }
</style>
