import { defineStore } from 'pinia';
import { axiosInstance } from '@/axiosInstance';

interface User {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    associes?: any[];
}

export const useApiStore = defineStore('useApiStore', {
    state: () => ({
        users: {} as User,
        invoices: [] as any[],
        posDetails: [] as any[],
        associes: [] as any[],
        posBrand: [] as any[],
        mandateMedia: [] as any[],
    }),
    getters: {
        allInvoices: (state) => state.invoices,
        allPosDetails: (state) => state.posDetails,
    },
    actions: {
        async setPosDetails() {
            this.posDetails = (await this.get('/user/pos-details')).data;
        },
        async resetUser() {
            try {
                const response = await this.get('/user/user-info');
                this.users = response.data;
            } catch (err) {
                console.error(err);
            }
        },
        async get(url: string): Promise<any> {
            return axiosInstance.get(url);
        },
        async post(url: string, payload: any, headers?: any): Promise<any> {
            return axiosInstance.post(url, payload, headers);
        },
        async patch(url: string, payload: any): Promise<any> {
            return axiosInstance.patch(url, payload);
        },
        async init() {
            try {
                const [userInfoResponse, userInvoicesResponse, posInfoResponse, mandateStatus, mandateMedia] = await Promise.allSettled([
                    this.get('/user/user-info'),
                    this.get('/user/user-invoices'),
                    this.get('/user/pos-details'),
                    this.get('/user/mandate-status'),
                    this.get('/medias/available-medias/mandate'),
                ]);
                if (userInfoResponse.status === 'fulfilled') {
                    this.users = userInfoResponse.value.data;
                    this.associes = userInfoResponse.value.data.associes || [];
                } else {
                    console.error('Failed to fetch user info:', userInfoResponse.reason);
                }

                if (userInvoicesResponse.status === 'fulfilled') {
                    this.$patch((state) => {
                        state.invoices = userInvoicesResponse.value.data;
                    });
                } else {
                    console.error('Failed to fetch user invoices:', userInvoicesResponse.reason);
                }

                if (posInfoResponse.status === 'fulfilled') {
                    this.posDetails = posInfoResponse.value.data;
                } else {
                    console.error('Failed to fetch POS details:', posInfoResponse.reason);
                }
                if (mandateStatus.status === 'fulfilled') {
                    // here update the status on mandate that are inside posDetails :()
                    // this.posDetails = mandateStatus.value.data;
                } else {
                    console.error('Failed to fetch POS details:', mandateStatus.reason);
                }
                if (mandateMedia.status === 'fulfilled') {
                    this.mandateMedia = mandateMedia.value.data;
                }
            } catch (err) {
                console.error('Unexpected error:', err);
            }
        },
        async updateProfile(payload: User) {
            try {
                const response = await this.patch('/user/update-info', payload);
                this.users = response.data.data;
                return { success: true, data: response.data };
            } catch (error) {
                return { success: false, error };
            }
        },
        async fetchMandateStatus() {
            try {
                const response = await this.get('/user/mandate-status');
            } catch (error) {
                console.error('Failed to fetch mandate statuses:', error);
            }
        },
    },
});
